import employerMainFindEmployeeElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_find_employee_element_shown';
import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { Title, VSpacing, GridColumn, GridRow, GridLayout } from '@hh.ru/magritte-ui';
import format from 'bloko/common/format';
import getConversionType from 'bloko/common/getConversionType';
import NumberFormatter from 'bloko/common/numberFormatter';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import Centered from 'src/pages/AnonymousEmployer/components/Centered/Centered';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import SearchForm from 'src/pages/AnonymousEmployer/components/ResumeSearch/SearchForm';

import styles from './styles.less';

const TrlKeys = {
    title: 'anonymousEmployer.resumeSearch.title',
    titleZp: 'anonymousEmployer.resumeSearch.title.zp',
    description: 'anonymousEmployer.resumeSearch.description',
    subtitleCounts: 'index.employer.search.subtitle.counts',
    subtitleApplicantMany: 'index.employer.search.subtitle.applicant.many',
    subtitleApplicantOne: 'index.employer.search.subtitle.applicant.one',
    siteNameShort: {
        113: 'authEmployer.regionWarning.siteNameShort.ru',
        5: 'authEmployer.regionWarning.siteNameShort.ua',
        40: 'authEmployer.regionWarning.siteNameShort.kz',
        9: 'authEmployer.regionWarning.siteNameShort.az',
        16: 'authEmployer.regionWarning.siteNameShort.by',
        28: 'authEmployer.regionWarning.siteNameShort.ge',
        48: 'authEmployer.regionWarning.siteNameShort.kg',
        97: 'authEmployer.regionWarning.siteNameShort.uz',
    },
};

const ResumeSearch: TranslatedComponent = ({ trls }) => {
    const resumeCount = useSelector((state) => state.globalStatistics?.resumes)!;
    const applicantCount = useSelector((state) => state.anonymousEmployer.applicantCount)!;
    const localeAreaId = useSelector((state) => state.locale.areaId);
    const shortNames = TrlKeys.siteNameShort as Record<string, string>;

    const formattedResumeCount = NumberFormatter.format(`${resumeCount}`, { groupSeparator: NON_BREAKING_SPACE });
    const formattedApplicantCount = NumberFormatter.format(`${applicantCount}`, { groupSeparator: NON_BREAKING_SPACE });

    const isZarplata = useIsZarplataPlatform();
    const isHHRu = useIsHhru();

    return (
        <GridLayout>
            <GridRow>
                <VSpacing default={80} xs={60} />
                <Centered>
                    <div className={styles.resumeSearchContainer}>
                        <GridColumn xs={4} s={6} m={8} l={12}>
                            <Title
                                Element="h2"
                                size="extra-large"
                                alignment="center"
                                description={
                                    isHHRu
                                        ? format(trls[TrlKeys.description], {
                                              '{0}': formattedResumeCount,
                                              '{1}': `${formattedApplicantCount}${NON_BREAKING_SPACE}${getConversionType(
                                                  {
                                                      one: trls[TrlKeys.subtitleApplicantOne],
                                                      some: trls[TrlKeys.subtitleApplicantMany],
                                                      many: trls[TrlKeys.subtitleApplicantMany],
                                                      value: applicantCount,
                                                  }
                                              )}`,
                                          })
                                        : undefined
                                }
                            >
                                {isZarplata
                                    ? trls[TrlKeys.titleZp]
                                    : format(trls[TrlKeys.title], {
                                          '{0}': trls[shortNames[localeAreaId]],
                                      })}
                            </Title>
                        </GridColumn>
                        <VSpacing default={40} />
                        <ElementShownAnchor fn={employerMainFindEmployeeElementShown}>
                            <SearchForm />
                        </ElementShownAnchor>
                    </div>
                </Centered>
                <VSpacing default={80} xs={60} />
            </GridRow>
        </GridLayout>
    );
};

export default translation(ResumeSearch);
