import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './styles.less';

interface HeroSectionProps {
    isWide?: boolean;
}

const HeroSection: FC<HeroSectionProps & PropsWithChildren> = ({ children, isWide }) => {
    return (
        <div
            className={classNames(styles.heroSection, {
                [styles.heroSectionWide]: isWide,
            })}
        >
            {children}
        </div>
    );
};

export default HeroSection;
