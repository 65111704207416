import { FC, ReactElement } from 'react';

import { Avatar, Cell, Text, useBreakpoint } from '@hh.ru/magritte-ui';

interface ListItemProps {
    icon: ReactElement;
    text: string;
}

const ListItem: FC<ListItemProps> = ({ text, icon }) => {
    const { isMobile } = useBreakpoint();
    return (
        <Cell
            avatar={<Avatar mode="icon" size={48} shapeCircle icon={icon} style="primary" aria-label={text} />}
            align="center"
        >
            <Text typography={isMobile ? 'label-3-regular' : 'label-2-regular'}>{text}</Text>
        </Cell>
    );
};

export default ListItem;
