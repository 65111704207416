import { useEffect, FC } from 'react';

import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import { useSelector } from 'src/hooks/useSelector';

import AnonymousEmployer from 'src/pages/AnonymousEmployer/AnonymousEmployer';

const AnonymousEmployerPage: FC = () => {
    const pageMetaData = useSelector((state) => state.pageMetaData);
    const sendDV360Analytics = useDV360Analytics();

    useEffect(() => {
        sendDV360Analytics('ANONYMOUS_EMPLOYER_SCREEN_SHOWN');
    }, [sendDV360Analytics]);

    return (
        <MagritteWrapper isEnabled>
            <PageLayout {...pageMetaData} layout={EmptyLayout}>
                <AnonymousEmployer />
            </PageLayout>
        </MagritteWrapper>
    );
};

export default AnonymousEmployerPage;
