import { useRef } from 'react';

import { TranslatedComponent, translation } from '@hh.ru/front-static-app';
import {
    Card,
    Text,
    Cell,
    Avatar,
    TooltipHover,
    BottomSheet,
    NavigationBar,
    VSpacing,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { GiftOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import useToggleState from 'src/hooks/useToggleState';

const TrlKeys = {
    text: 'index.employer.benefits.gifts.banner',
    tipTitle: 'index.employer.benefits.gifts.tip.title',
    tip: 'index.employer.benefits.gifts.tip',
};

const BenefitsBanner: TranslatedComponent = ({ trls }) => {
    const bannerRef = useRef(null);
    const { isMobile } = useBreakpoint();
    const [isBottomSheetOpened, toggleBottomSheet] = useToggleState(false);

    return (
        <>
            <Card
                onClick={isMobile ? toggleBottomSheet : undefined}
                ref={bannerRef}
                stretched
                padding={16}
                borderRadius={16}
                style="special-secondary"
            >
                <Cell
                    avatar={
                        <Avatar
                            mode="icon"
                            size={48}
                            shapeCircle
                            icon={<GiftOutlinedSize24 initialColor="special" />}
                            style="primary"
                            aria-label={trls[TrlKeys.text]}
                        />
                    }
                    align="center"
                >
                    <Text typography="label-2-regular">{trls[TrlKeys.text]}</Text>
                </Cell>
            </Card>
            {!isMobile && (
                <TooltipHover activatorRef={bannerRef} placement="bottom-center">
                    {trls[TrlKeys.tip]}
                </TooltipHover>
            )}
            {isMobile && (
                <BottomSheet
                    visible={isBottomSheetOpened}
                    header={<NavigationBar title={trls[TrlKeys.tipTitle]} />}
                    onClose={toggleBottomSheet}
                >
                    <Text typography="label-2-regular">{trls[TrlKeys.tip]}</Text>
                    <VSpacing default={40} />
                </BottomSheet>
            )}
        </>
    );
};

export default translation(BenefitsBanner);
