import { FC, PropsWithChildren } from 'react';

import { Card, CardProps } from '@hh.ru/magritte-ui';

import styles from './styles.less';

interface CardSectionProps {
    style: CardProps['style'];
}

const CardSection: FC<CardSectionProps & PropsWithChildren> = ({ style, children }) => {
    return (
        <div className={styles.cardSection}>
            <Card borderRadius={40} style={style} stretched>
                {children}
            </Card>
        </div>
    );
};

export default CardSection;
