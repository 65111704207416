import { FC, PropsWithChildren, ReactNode, useRef } from 'react';

import { TooltipHover, useBreakpoint, BottomSheet, Link, NavigationBar, VSpacing, Text } from '@hh.ru/magritte-ui';

import useToggleState from 'src/hooks/useToggleState';

interface LandingTooltipProps {
    title: ReactNode;
    tooltipText: ReactNode;
    'data-qa': string;
}

const LandingTooltip: FC<LandingTooltipProps & PropsWithChildren> = (props) => {
    const { title, tooltipText, children } = props;
    const activatorRef = useRef(null);
    const { isMobile } = useBreakpoint();
    const [isBottomSheetOpened, toggleBottomSheet] = useToggleState(false);
    return (
        <>
            {isMobile ? (
                <BottomSheet
                    visible={isBottomSheetOpened}
                    header={<NavigationBar title={title} />}
                    onClose={toggleBottomSheet}
                >
                    <Text typography="label-2-regular">{tooltipText}</Text>
                    <VSpacing default={40} />
                </BottomSheet>
            ) : (
                <TooltipHover activatorRef={activatorRef} placement="top-center">
                    {tooltipText}
                </TooltipHover>
            )}

            <Link onClick={toggleBottomSheet} data-qa={props['data-qa']} ref={activatorRef} inline>
                {children}
            </Link>
        </>
    );
};

export default LandingTooltip;
