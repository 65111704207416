import { FC, useState } from 'react';

import useAnimatedNumber from 'src/hooks/useAnimatedNumber';
import useInView from 'src/hooks/useInView';

import styles from 'src/pages/AnonymousEmployer/components/Benefits/BenefitsChart/benefits-chart.less';

interface BenefitsChartProps {
    percent: number;
}

const SVG_SIZE = 120;
const STROKE_WIDTH = 16;
const ANIMATION_DURATION = 1200;

const BenefitsChart: FC<BenefitsChartProps> = ({ percent = 0 }) => {
    const radius = SVG_SIZE / 2;
    const insetRadius = radius - STROKE_WIDTH / 2;
    const strokeDasharray = Math.PI * (insetRadius * 2);
    const strokeDashoffset = strokeDasharray - (percent / 100) * strokeDasharray;
    const [isAnimated, setIsAnimated] = useState(false);
    const animatedNumber = useAnimatedNumber({
        value: percent,
        duration: ANIMATION_DURATION,
        startAnimation: isAnimated,
        timingFunction: 'ease-in-out',
    });
    const chartRef = useInView<HTMLDivElement>(() => {
        setIsAnimated(true);
    });

    return (
        <div className={styles.container} ref={chartRef}>
            <svg
                width="100%"
                height="100%"
                viewBox={`0 0 ${SVG_SIZE} ${SVG_SIZE}`}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    className={styles.circleBackground}
                    r={insetRadius}
                    cx={radius}
                    cy={radius}
                    fill="none"
                    strokeDasharray="0"
                    strokeDashoffset="0"
                    strokeWidth={STROKE_WIDTH}
                />
                <circle
                    className={styles.circlePercent}
                    r={insetRadius}
                    cx={radius}
                    cy={radius}
                    fill="none"
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={strokeDashoffset}
                    transform={`rotate(-90,${radius},${radius})`}
                    strokeWidth={STROKE_WIDTH}
                    style={{
                        strokeDashoffset: isAnimated ? strokeDashoffset : 326,
                        transition: `stroke-dashoffset ${ANIMATION_DURATION}ms ease-in-out`,
                    }}
                />
            </svg>
            <div className={styles.contentWrapper}>
                <div className={styles.content}>{Math.round(animatedNumber)}%</div>
            </div>
        </div>
    );
};

export default BenefitsChart;
