import { useRef, useState } from 'react';

import employerMainFindEmployeeButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_find_employee_button_click';
import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { GridColumn, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';

import paths from 'src/app/routePaths';
import Form from 'src/components/Form';
import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import { useSelector } from 'src/hooks/useSelector';
import LandingButton from 'src/pages/AnonymousEmployer/components/LandingButton/LandingButton';

import Fieldset from 'src/pages/AnonymousEmployer/components/ResumeSearch/Fieldset';
import FrequentlySearch from 'src/pages/AnonymousEmployer/components/ResumeSearch/FrequentlySearch';
import SearchInput from 'src/pages/AnonymousEmployer/components/ResumeSearch/SearchInput';

import styles from './styles.less';

const TrlKeys = {
    find: 'anonymousEmployer.resumeSearch.form.find',
};

const SearchForm: TranslatedComponent = ({ trls }) => {
    const professions = useSelector((state) => state.anonymousEmployer.suggestedProfessions);
    const { isMobile } = useBreakpoint();
    const sendDV360Analytics = useDV360Analytics();
    const [searchText, setSearchText] = useState('');

    const sendButtonAnalytics = () => {
        sendDV360Analytics('ANONYMOUS_EMPLOYER_PAGE_FIND_EMPLOYMENT_BUTTON_CLICK');
        employerMainFindEmployeeButtonClick({
            text: searchText,
        });
    };

    const formRef = useRef<HTMLFormElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const submitForm = () => {
        formRef.current?.submit();
    };

    const setInputValueAndSubmit = (suggest: string): void => {
        if (inputRef.current) {
            inputRef.current.value = suggest;
        }
        submitForm();
    };

    return (
        <GridColumn xs={4} s={8} m={10} l={10}>
            <Form action={paths.resumeSearch} method="GET" ref={formRef}>
                <Fieldset />
                <div className={styles.searchFormContainer}>
                    <div className={styles.searchGroup}>
                        <SearchInput
                            searchText={searchText}
                            onSearchTextChange={setSearchText}
                            onSuggestPick={submitForm}
                            innerRef={inputRef}
                        />
                        {!isMobile && (
                            <LandingButton
                                type="submit"
                                mode="primary"
                                size="large"
                                onClick={sendButtonAnalytics}
                                data-qa="employer-index-search-submit"
                            >
                                {trls[TrlKeys.find]}
                            </LandingButton>
                        )}
                    </div>
                    <VSpacing default={28} />
                    <GridColumn xs={4} s={8} m={10} l={10}>
                        <FrequentlySearch items={professions} onItemClick={setInputValueAndSubmit} />
                    </GridColumn>
                </div>
            </Form>
        </GridColumn>
    );
};

export default translation(SearchForm);
