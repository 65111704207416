import { FC, PropsWithChildren } from 'react';

import { Text } from '@hh.ru/magritte-ui';

import styles from './styles.less';

const CustomTag: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className={styles.customTag}>
            <Text typography="label-3-regular" style="contrast">
                {children}
            </Text>
        </div>
    );
};

export default CustomTag;
