import { TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { VSpacing, GridColumn, GridRow, Title, GridLayout } from '@hh.ru/magritte-ui';

import StaticImg from 'src/components/StaticImg';

import CandidatesList from 'src/pages/AnonymousEmployer/components/Candidates/CandidatesList';

import styles from './candidates.less';

const TrlKeys = {
    title: 'index.employer.candidates.title',
};

const Candidates: TranslatedComponent = ({ trls }) => {
    return (
        <GridLayout>
            <VSpacing default={80} />
            <GridRow>
                <GridColumn xs={4} s={8} m={6}>
                    <StaticImg
                        className={styles.img}
                        path="/images/hh/index/candidates.svg"
                        alt={trls[TrlKeys.title]}
                    />
                    <VSpacing default={40} />
                </GridColumn>
                <GridColumn xs={4} s={8} m={6}>
                    <Title Element="h2" size="extra-large">
                        {trls[TrlKeys.title]}
                    </Title>
                    <VSpacing default={40} />
                    <CandidatesList />
                </GridColumn>
            </GridRow>
            <VSpacing default={80} />
        </GridLayout>
    );
};

export default translation(Candidates);
