import { PropsWithChildren } from 'react';

import employerMainNewPriceDbAccessOfferButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_new_price_db_access_offer_button_click';
import employerMainNewPriceVacancyOfferButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_new_price_vacancy_offer_button_click';
import { Text, Card, useBreakpoint, Button, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import { useIsHhru } from 'src/hooks/useSites';
import { CardType } from 'src/pages/AnonymousEmployer/components/Price/types';

import CustomTag from 'src/pages/AnonymousEmployer/components/Price/PriceCard/CustomTag/CustomTag';
import ListItem from 'src/pages/AnonymousEmployer/components/Price/PriceCard/ListItem';
import PriceCardImage from 'src/pages/AnonymousEmployer/components/Price/PriceCard/PriceCardImage';

import styles from './card.less';

export interface PriceCardOption {
    icon: JSX.Element;
    text: string;
}

interface PriceCardProps {
    type: CardType;
    header: string;
    action: string;
    toLink: string;
    description: string;
    cardOptions: PriceCardOption[];
}

const TrlKeys = {
    tag: 'index.employer.priceSection.recommend',
};

const PriceCard: TranslatedComponent<PriceCardProps & PropsWithChildren> = ({
    type,
    header,
    action,
    toLink,
    description,
    cardOptions,
    trls,
}) => {
    const sendDV360Analytics = useDV360Analytics();
    const isHHRu = useIsHhru();

    const handleClick = () => {
        if (type === CardType.Candidates) {
            sendDV360Analytics('ANONYMOUS_EMPLOYER_PAGE_PRICE_CANDIDATES_BUTTON_CLICK');
            employerMainNewPriceDbAccessOfferButtonClick();
        }
        if (type === CardType.Vacancies) {
            employerMainNewPriceVacancyOfferButtonClick();
        }
    };
    const { isMobile } = useBreakpoint();

    const optionsList = (
        <>
            {cardOptions.map(({ icon, text }) => (
                <ListItem key={text} text={text} icon={icon} />
            ))}
        </>
    );

    return (
        <div className={styles.container}>
            <Card
                padding={12}
                borderRadius={24}
                verticalStretched
                borderWidth="default"
                style={type === CardType.Vacancies ? 'accent-secondary' : 'secondary'}
            >
                <div className={styles.cardContentContainer}>
                    <PriceCardImage type={type} />
                    <VSpacing default={24} />
                    <div className={styles.cardListContainer}>
                        <div className={styles.headerContainer}>
                            <Text typography="title-5-semibold">{header}</Text>
                            {type === CardType.Vacancies && <CustomTag>{trls[TrlKeys.tag]}</CustomTag>}
                        </div>
                        <VSpacing default={8} />
                        <Text typography={isMobile ? 'label-3-regular' : 'label-2-regular'} style="secondary">
                            {description}
                        </Text>

                        {type === CardType.Vacancies && (
                            <>
                                <VSpacing default={28} s={20} xs={isHHRu ? 24 : 28} />
                                <VSpacingContainer default={24} s={isHHRu ? 12 : 24}>
                                    {optionsList}
                                </VSpacingContainer>
                                <VSpacing default={40} s={24} xs={isHHRu ? 24 : 32} />
                            </>
                        )}

                        {type === CardType.Candidates && (
                            <>
                                <VSpacing default={28} s={28} xs={isHHRu ? 28 : 24} />
                                <VSpacingContainer default={24}>{optionsList}</VSpacingContainer>
                                <VSpacing default={40} s={isHHRu ? 44 : 28} xs={24} />
                            </>
                        )}
                    </div>
                    <Button
                        data-qa={`${type}-price-card-action`}
                        Element={SPALink}
                        to={toLink}
                        stretched
                        mode="primary"
                        style="accent"
                        onClick={handleClick}
                    >
                        {action}
                    </Button>
                </div>
            </Card>
        </div>
    );
};

export default translation(PriceCard);
