import { useState, useEffect, RefObject } from 'react';

const useVisibilityPercentage = (ref: RefObject<HTMLElement>) => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        const calculateVisibility = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;

                if (rect.top < 0 && rect.bottom >= 0) {
                    setPercentage(100);
                    return;
                }

                const visibleTop = Math.max(0, rect.top);
                const visibleBottom = Math.min(viewportHeight, rect.bottom);
                const visibleHeight = visibleBottom - visibleTop;

                const elementHeight = rect.height;
                const percent = (visibleHeight / elementHeight) * 100;

                setPercentage(Math.round(Math.min(100, Math.max(0, percent))));
            }
        };

        calculateVisibility();
        window.addEventListener('scroll', calculateVisibility);
        window.addEventListener('resize', calculateVisibility);

        return () => {
            window.removeEventListener('scroll', calculateVisibility);
            window.removeEventListener('resize', calculateVisibility);
        };
    }, [ref]);

    return percentage;
};

export default useVisibilityPercentage;
