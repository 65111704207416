import { FC } from 'react';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useIsMiddleAsia, useIsRabotaBy } from 'src/hooks/useSites';

import Benefits from 'src/pages/AnonymousEmployer/components/Benefits/Benefits';
import Candidates from 'src/pages/AnonymousEmployer/components/Candidates';
import CardSection from 'src/pages/AnonymousEmployer/components/CardSection/CardSection';
import HeroSection from 'src/pages/AnonymousEmployer/components/HeroSection/HeroSection';
import Price from 'src/pages/AnonymousEmployer/components/Price';
import PublishVacancy from 'src/pages/AnonymousEmployer/components/PublishVacancy/PublishVacancy';
import ResumeSearch from 'src/pages/AnonymousEmployer/components/ResumeSearch';
import SearchSteps from 'src/pages/AnonymousEmployer/components/SearchSteps';
import Support from 'src/pages/AnonymousEmployer/components/Support';

const AnonymousEmployer: FC = () => {
    const isZp = useIsZarplataPlatform();
    const isRabotaBy = useIsRabotaBy();
    const isMiddleAsia = useIsMiddleAsia();

    if (isRabotaBy) {
        return (
            <>
                <HeroSection>
                    <PublishVacancy />
                </HeroSection>
                <CardSection style="secondary">
                    <ResumeSearch />
                </CardSection>
                <Price />
                <CardSection style="secondary">
                    <SearchSteps />
                </CardSection>
                <Benefits />
                <CardSection style="accent-secondary">
                    <Candidates />
                </CardSection>
                <Support />
            </>
        );
    }

    if (isMiddleAsia) {
        return (
            <>
                <HeroSection>
                    <PublishVacancy />
                </HeroSection>
                <CardSection style="secondary">
                    <ResumeSearch />
                </CardSection>
                <Price />
                <CardSection style="secondary">
                    <SearchSteps />
                </CardSection>
                <Support />
            </>
        );
    }

    if (isZp) {
        return (
            <>
                <HeroSection isWide>
                    <PublishVacancy />
                </HeroSection>
                <CardSection style="secondary">
                    <ResumeSearch />
                    <SearchSteps />
                </CardSection>
                <Benefits />
                <CardSection style="secondary">
                    <Candidates />
                </CardSection>
                <Support />
            </>
        );
    }

    return (
        <>
            <HeroSection>
                <PublishVacancy />
            </HeroSection>
            <CardSection style="secondary">
                <ResumeSearch />
            </CardSection>
            <Price />
            <CardSection style="secondary">
                <SearchSteps />
            </CardSection>
            <Benefits />
            <CardSection style="accent-secondary">
                <Candidates />
            </CardSection>
            <Support />
        </>
    );
};

export default AnonymousEmployer;
