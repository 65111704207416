import { FC } from 'react';
import classnames from 'classnames';

import { useIsHhru } from 'src/hooks/useSites';
import { CardType } from 'src/pages/AnonymousEmployer/components/Price/types';

import styles from './styles.less';

const PriceCardImage: FC<{ type: CardType }> = ({ type }) => {
    const isHhRu = useIsHhru();
    return (
        <div
            className={classnames(styles.priceCardImage, {
                [styles.imageVacancies]: type === CardType.Vacancies,
                [styles.imageCandidates]: type === CardType.Candidates,
                [styles.altImage]: !isHhRu,
            })}
        />
    );
};

export default PriceCardImage;
