import { Title, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { Benefits } from 'src/pages/AnonymousEmployer/components/Benefits/types';

import styles from './benefits-list.less';

const TrlKeys = {
    [Benefits.Applicants]: {
        title: 'index.employer.benefits.applicants.title',
    },
    [Benefits.Vacancies]: {
        title: 'index.employer.benefits.vacancies.title',
    },
    [Benefits.Employers]: {
        title: 'index.employer.benefits.employers.title',
    },
};

const BenefitsListZarplata: TranslatedComponent = ({ trls }) => {
    const { isXS } = useBreakpoint();
    return (
        <div className={styles.benefitsList}>
            {Object.keys(Benefits).map((benefit) => {
                const type = Benefits[benefit];
                return (
                    <div className={styles.benefitsListItem} key={type}>
                        <div className={styles.benefitsListItemImage} />
                        <div>
                            <Title Element="h3" size={isXS ? 'small' : 'medium'}>
                                {trls[TrlKeys[type].title]}
                            </Title>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default translation(BenefitsListZarplata);
