import employerMainPublishVacancyOfferButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_publish_vacancy_offer_button_click';
import { TranslatedComponent, translation, formatToReactComponent } from '@hh.ru/front-static-app';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import FormatMoney from 'src/components/FormatMoney';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import LandingButton from 'src/pages/AnonymousEmployer/components/LandingButton/LandingButton';

const TrlKeys = {
    action: 'index.employer.benefits.action',
    actionNoPrice: 'index.employer.benefits.action.no.price',
};

const PublishVacancyButton: TranslatedComponent = ({ trls }) => {
    const publicationPrice = useSelector(({ anonymousEmployer }) => anonymousEmployer.publicationPrice);
    const { currency, price } = publicationPrice || {};
    const isZP = useIsZarplataPlatform();
    const { isS } = useBreakpoint();

    const handleClick = () => {
        employerMainPublishVacancyOfferButtonClick();
    };

    return (
        <LandingButton
            Element={SPALink}
            data-qa="employer-index-publish-vacancy-offer"
            to="/auth/employer?backurl=/employer/vacancy/create&from=employer_index_content&hhtmFromLabel=employer_index_content"
            size="large"
            style="accent"
            mode="primary"
            stretched={!isS}
            onClick={handleClick}
        >
            {!isZP && price && currency ? (
                <FormatMoney
                    currency={currency}
                    inCents
                    render={(amount, currencyNode) =>
                        formatToReactComponent(trls[TrlKeys.action], {
                            '{0}': (
                                <>
                                    <span data-qa="employer-index-vacancy-price__amount">{amount}</span>{' '}
                                    <span data-qa="employer-index-vacancy-price__currency">{currencyNode}</span>
                                </>
                            ),
                        })
                    }
                >
                    {price}
                </FormatMoney>
            ) : (
                trls[TrlKeys.actionNoPrice]
            )}
        </LandingButton>
    );
};

export default translation(PublishVacancyButton);
